import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class VersionCheckService {

    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    private messageDisplayed = false;

    constructor(private http: HttpClient,
                private toastrService: ToastrService) { }

    public initVersionCheck(url, frequency = 1000 * 60 * 30) {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    }

    private checkVersion(url) {
        if (!url) { return; }
        this.http.get(url + '?noLoader=true&t=' + new Date().getTime())
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);
                    if (hashChanged && !this.messageDisplayed) {
                        this.messageDisplayed = true;
                        this.toastrService.info(
                            '<div class="small">O Food Checker foi atualizado para uma nova versão! É necessário atualizar a página. Deseja fazer isso agora?' +
                            '<div class="text-right">' +
                            '<a href="#" class="btn btn-xs btn-success btn-reload">Atualizar!</a>' +
                            '</div></div>',
                            'Nova versão disponível',
                            {
                                enableHtml: true,
                                closeButton: true,
                                tapToDismiss: false,
                                disableTimeOut: true,
                                positionClass: 'toast-bottom-right',
                                toastClass: 'ngx-toastr toastr-reload',
                            }
                        );
                    }
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash;
    }
}
