import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from '../../../../../shared/services/settings.service';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.css'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        RouterLinkActive,
        TranslateModule
    ]
})
export class MainMenuComponent {
    @Input() settingsService: SettingsService;
}
