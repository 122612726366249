import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { IonApp, IonContent, IonRefresher, IonRefresherContent } from '@ionic/angular/standalone';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { setTheme } from 'ngx-bootstrap/utils';
import * as LiveUpdates from '@capacitor/live-updates';

import { environment } from '../environments/environment';

import { VersionCheckService } from './shared/services/version-check.service';
import { CookieService } from './shared/services/cookie.service';
import { PushNotificationService } from './shared/services/push-notification.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    imports: [ RouterOutlet, IonApp, IonRefresher, IonRefresherContent, IonContent ]
})
export class AppComponent implements OnInit {

    title = 'Food Checker';
    device = 'desktop';
    app = '';
    platform = '';

    constructor(
        private versionCheckService: VersionCheckService,
        private route: ActivatedRoute,
        private cookieService: CookieService
    ) {
        setTheme('bs4');
    }

    ngOnInit() {

        if (Capacitor.isNativePlatform()) {
            this.initializeNativeFeatures();
        }

        this.setupDeviceDetection();
        this.handleRouteParams();
        this.initializeVersionCheck();
        this.saveUtmsInCookies();
    }

    public handleRefresh(event: CustomEvent) {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    private setupDeviceDetection() {

        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            this.device = 'no-desktop';
        }

        this.app = localStorage.getItem('fcIsApp') === 'true' ? 'is-app' : '';
        this.platform = localStorage.getItem('fcPlatform');

        if (Capacitor.isNativePlatform()) {
            localStorage.setItem('fcIsApp', 'true');
            this.app = 'is-app';
        }
    }

    private handleRouteParams() {
        this.route.queryParams.subscribe(params => {
            this.processReturnUrl(params);
            this.processAppParams(params);
            this.processAffiliateId(params);
        });
    }

    private processReturnUrl(params: any) {
        if (params.returnUrl) {
            const decodedParams = new URLSearchParams(decodeURIComponent(params.returnUrl.replace('/', '')));
            if (decodedParams.get('app') === 'true') {
                localStorage.setItem('fcIsApp', 'true');
                this.app = 'is-app';
            }
            if (decodedParams.get('platform')) {
                localStorage.setItem('fcPlatform', decodedParams.get('platform'));
                this.platform = decodedParams.get('platform');
            }
        }
    }

    private processAppParams(params: any) {
        if (params.app === 'true') {
            localStorage.setItem('fcIsApp', 'true');
            this.app = 'is-app';
        }

        if (params.platform) {
            localStorage.setItem('fcPlatform', params.platform);
            this.platform = params.platform;
        }
    }

    private processAffiliateId(params: any) {
        if (params.a) {
            this.cookieService.set('affid', params.a, 7, '/');
        }
    }

    private initializeVersionCheck() {
        if (this.app !== 'is-app' && !Capacitor.isNativePlatform()) {
            this.versionCheckService.initVersionCheck(environment.VERSION_CHECK_URL);
        }
    }

    private async initializeNativeFeatures() {
        try {
            await this.initializeStatusBar();
            await this.initializeAppListeners();
            await this.initializeLiveUpdates();
        } catch (error) {
            console.error('Erro ao inicializar recursos nativos:', error);
        }
    }

    private async initializeStatusBar() {
        try {
            await StatusBar.setBackgroundColor({ color: '#00A76D' });
            await StatusBar.setStyle({ style: Style.Dark });
            await StatusBar.setOverlaysWebView({ overlay: false });
            await SplashScreen.hide();
        } catch (error) {
            console.error('Erro ao configurar a StatusBar:', error);
        }
    }

    private async initializeAppListeners() {
        try {
            await App.removeAllListeners();

            await App.addListener('appStateChange', ({ isActive }) => {
                if (!isActive) {
                    this.handleAppBackground();
                }
            });

            await App.addListener('resume', () => {
                this.handleAppResume();
            });
        } catch (error) {
            console.error('Erro ao inicializar listeners do App:', error);
        }
    }

    private async handleAppBackground() {
        // Implementar lógica necessária quando o app vai para background
    }

    private async handleAppResume() {
        const shouldReload = JSON.parse(localStorage.getItem('fcShouldReloadApp') || 'false');
        if (shouldReload) {
            await LiveUpdates.reload();
        } else {
            await this.checkForUpdates();
        }
    }

    private async initializeLiveUpdates() {
        App.addListener('resume', async () => {
            await this.handleAppResume();
        });
    }

    private async checkForUpdates() {
        try {
            const result = await LiveUpdates.sync();
            if (result.activeApplicationPathChanged) {
                localStorage.setItem('fcShouldReloadApp', JSON.stringify(true));
            }
        } catch (error) {
            console.error('Erro ao verificar atualizações:', error);
        }
    }

    private saveUtmsInCookies() {
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = this.getUtmParams(urlParams);

        if (Object.values(utmParams).some(value => value)) {
            this.setUtmCookie(utmParams);
        }
    }

    private getUtmParams(urlParams: URLSearchParams) {
        return {
            affid: urlParams.get('a') || urlParams.get('affid') || '',
            utm_source: urlParams.get('utm_source') === 'homescreen' ? '' : urlParams.get('utm_source') || '',
            utm_medium: urlParams.get('utm_medium') || '',
            utm_campaign: urlParams.get('utm_campaign') || '',
            utm_term: urlParams.get('utm_term') || '',
            utm_content: urlParams.get('utm_content') || ''
        };
    }

    private setUtmCookie(utmData: any) {
        const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.foodchecker.com.br';
        const expirationDate = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toUTCString();

        document.cookie = `fc_utm_data=${encodeURIComponent(JSON.stringify(utmData))}; expires=${expirationDate}; path=/; domain=${cookieDomain}`;
    }
}
