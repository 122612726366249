<div class="app-body {{ values.bootstrap }} {{ pageClass }} {{ pageClassTips }} {{ pageClassFirstSteps }}
     {{ pageClassRequired }} {{ pageClassMultiusers }} a{{ accountId }} u{{ userId }} c{{ clientId }}"
     [attr.w]="values.width" [attr.h]="values.height">

    <app-admin-alerts [registered]="registered" [userName]="userName" [isIos]="isIos" [isAndroid]="isAndroid"></app-admin-alerts>

    <header>
        <nav class="navbar navbar-expand-lg navbar-dark bg-success">
            <div class="navbar-back-button d-lg-none">
               <a id="back-button" href="javascript:;" (click)="backButtonNavigate($event)" style="display: none;">
                   <span class="fa fa-chevron-left"></span>
               </a>
           </div>
           <a class="navbar-brand text-center text-lg-left pb-2 p-lg-0" routerLink="/">
               <img src="{{ logoHeader }}" alt=""/>
           </a>
           <div class="d-flex justify-content-bettewen">
               <app-qr-code></app-qr-code>
               <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                   <div class="badge-wrapper">
                       <span class="navbar-toggler-icon"></span>
                       <span class="badge badge-danger badge-notifications float-right float-lg-none"></span>
                       <span class="badge badge-warning badge-syncing float-right float-lg-none"></span>
                    </div>
                </button>
            </div>
            <div class="collapse navbar-collapse" id="navbarNav">
                <app-main-menu class="mr-auto" [settingsService]="settingsService"></app-main-menu>
                <app-user-nav [userPhoto]="userPhoto" [userName]="userName" [userId]="userId"></app-user-nav>
            </div>
        </nav>

        <app-breadcrumb [clientId]="clientId" [clientName]="clientName" [clientType]="clientType"></app-breadcrumb>

        <nav class="navbar navbar-client navbar-expand-lg navbar-light bg-primary {{ 'bg-client-' + clientType }}" *ngIf="clientId">
            <a class="navbar-brand p-0 d-lg-none" [routerLink]="['/clients', clientId, 'view']">
                <i class="fa fa-building-o mt-1 mr-1"></i> {{ clientName }}
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavClient" aria-controls="navbarNavClient" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavClient">
                <app-client-menu [clientId]="clientId"></app-client-menu>
            </div>
        </nav>

        <div class="progress-bar-loading">
            <div class="progress" *ngIf="loader.show || loaderNavigation">
                <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </div>
        </div>
    </header>

    <div class="container-fluid container-page pt-lg-3" [ngClass]="{ 'container-loader': loader.show }">
        <div class="page-header d-none d-lg-block mb-4" *ngIf="title && title !== 'Home'">
            <div class="page-title pl-0 pl-sm-2">
                <h1 class="h4 text-center text-sm-left">
                    <i class="fa fa-file-text-o"></i> {{ title }}
                </h1>
            </div>
        </div>
        <router-outlet></router-outlet>
        <div class="loader-backdrop"></div>
        <div class="loader-spinner"><i class="fa fa-spinner fa-spin"></i></div>
    </div>

    <footer class="d-none">
        <div class="container">
            <div class="row">
                <div class="col-lg">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <a routerLink="/clients"><i class="fa fa-group"></i> Clientes</a>
                        </li>
                        <li class="list-inline-item">
                            <a routerLink="/legislations"><i class="fa fa-file-text"></i> Legislações</a>
                        </li>
                        <li class="list-inline-item">
                            <a routerLink="https://api.whatsapp.com/send?phone=5511963722475&text=Digite%20aqui%20sua%20d%C3%BAvida..." target="_blank" rel="external"><i class="fa fa-question-circle"></i> Suporte</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    <div class="footer-scroll"></div>

</div>
