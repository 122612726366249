<ul class="nav navbar-nav">
    <li class="nav-item" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-visits')">
        <a class="nav-link pl-0" [routerLink]="['/clients', clientId, 'visits']">{{ 'Visitas' | translate }}</a>
    </li>
    <li class="nav-item only-development" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-sheets')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'forms', 'performeds']">{{ 'Planilhas' | translate }}</a>
    </li>
    <li class="nav-item dropdown hide-development" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-sheets')">
        <a class="nav-link dropdown-toggle dropdown-link" href="#" id="moreDropdown3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ 'Planilhas' | translate}}</a>
        <div class="dropdown-menu navbar-dropdown" aria-labelledby="moreDropdown3">
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'temperature-foods']">Temperatura de Alimentos</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'temperature-equipments']">Temperatura de Equipamentos</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'cleanings-equipments']">Limpeza de Equipamentos</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'cleanings-installations']">Limpeza de Instalações</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'wastes']">Controle de Desperdícios</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'oil-controls']">Controle de Óleos</a>
            <a class="dropdown-item d-none" [routerLink]="['/clients', clientId, 'sheets', 'food-samples']">Controle de Amostras</a>
            <a class="dropdown-item d-none" [routerLink]="['/clients', clientId, 'sheets', 'receiving-products']">Recebimento de Mercadoria</a>
            <a class="dropdown-item d-none" [routerLink]="['/clients', clientId, 'sheets', 'occurrence-pests-vectors']">Ocorrência de Vetores e Pragas</a>
            <a class="dropdown-item d-none" [routerLink]="['/clients', clientId, 'sheets', 'grocery-lists']">Lista de Compras</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'alizarol-tests']" *ngIf="!settingsService.getBool('no-menu-alizarol-tests')">Testes de Alizarol</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'sheets', 'fosfatase-tests']" *ngIf="!settingsService.getBool('no-menu-fosfatase-tests')">Testes de Fosfatase</a>
        </div>
    </li>
    <li class="nav-item hide-app-inactive" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-checklists-performeds')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'checklists', 'performeds']">{{ 'Checklists' | translate}}</a>
    </li>
    <li class="nav-item" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-action-plans')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'action-plans']">{{ 'Planos de Ação' | translate }}</a>
    </li>
    <li class="nav-item hide-app-inactive" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-documents')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'documents']">{{ 'Documentos' | translate }}</a>
    </li>
    <li class="nav-item" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-recipes')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'recipes']">{{ 'Receitas' | translate }}</a>
    </li>
    <li class="nav-item hide-app-inactive" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-labels')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'labels']">{{ 'Rotulagem' | translate }}</a>
    </li>
    <li class="nav-item hide-app-inactive" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-datasheets')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'datasheets']">{{ 'Fichas Técnicas' | translate }}</a>
    </li>
    <li class="nav-item hide-app-inactive" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-sops')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'sops']">{{ "POP's ou IT's" | translate }}</a>
    </li>
    <li class="nav-item dropdown nav-item-more">
        <a class="nav-link dropdown-toggle dropdown-link" href="#" id="moreDropdown2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ 'Mais' | translate }}...</a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="moreDropdown2">
            <a class="dropdown-item hide-app-inactive" [routerLink]="['/clients', clientId, 'trainings']">{{ 'Treinamentos' | translate}}</a>
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'contacts']">{{ 'Equipe' | translate }}</a>
            <a class="dropdown-item hide-app-inactive" [routerLink]="['/clients', clientId, 'reports', 'new']">{{ 'Relatórios' | translate}}</a>
            <a class="dropdown-item dropdown-toggle dropdown-link pr-0 {{settingsService.getBool('show-menu-configs') ?'':'not-role-4'}}" href="#" id="moreDropdown10" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ 'Configurações' | translate}}</a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown dropleft" aria-labelledby="moreDropdown10">
                <a class="dropdown-item" [routerLink]="['/clients', clientId, 'checklists']">{{ 'Modelos de Checklists' | translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sops', 'items']">{{ "Modelos de POP's ou IT's'"| translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'documents', 'items']">{{ 'Tipos de Documentos' | translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sheets', 'equipments']">{{ 'Equipamentos' | translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sheets', 'foods']">{{ 'Alimentos' | translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sheets', 'uniforms-epis']">{{ 'Uniformes e EPIs' | translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'projects']" *ngIf="!settingsService.getBool('no-menu-projects')">{{ 'Pastas' | translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'categories', 'cargos']" *ngIf="!settingsService.getBool('no-menu-categories')">{{ 'Cargos' | translate}}</a>
                <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'categories', 'segmentos']" *ngIf="!settingsService.getBool('no-menu-categories')">{{ 'Segmentos' | translate}}</a>
                <a class="dropdown-item not-role-4 hide-app-inactive" [routerLink]="['/clients', clientId, 'users', 'clients']">{{ 'Acesso do Cliente' | translate}}</a>
            </div>
        </div>
    </li>
    <li class="nav-item nav-item-not-more hide-app-inactive" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-trainings')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'trainings']">{{ 'Treinamentos' | translate }}</a>
    </li>
    <li class="nav-item nav-item-not-more" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-contacts')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'contacts']">{{ 'Equipe' | translate }}</a>
    </li>
    <li class="nav-item nav-item-not-more hide-app-inactive" routerLinkActive="active" *ngIf="!settingsService.getBool('no-menu-reports')">
        <a class="nav-link" [routerLink]="['/clients', clientId, 'reports', 'new']">{{ 'Relatórios' | translate }}</a>
    </li>
    <li class="nav-item dropdown nav-item-not-more {{settingsService.getBool('show-menu-configs') ?'':'not-role-4'}}" *ngIf="!settingsService.getBool('no-menu-client-configs')">
        <a class="nav-link dropdown-toggle dropdown-link pr-0" href="#" id="moreDropdown8" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ 'Configurações' | translate}}</a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="moreDropdown8">
            <a class="dropdown-item" [routerLink]="['/clients', clientId, 'checklists']" *ngIf="!settingsService.getBool('no-menu-checklists')">{{ 'Modelos de Checklists' | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sops', 'items']" *ngIf="!settingsService.getBool('no-menu-sops')">{{ "Modelos de POP's ou IT's" | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'documents', 'items']" *ngIf="!settingsService.getBool('no-menu-documents')">{{ 'Tipos de Documentos' | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sheets', 'equipments']" *ngIf="!settingsService.getBool('no-menu-sheets')">{{ 'Equipamentos' | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sheets', 'foods']" *ngIf="!settingsService.getBool('no-menu-sheets')">{{ 'Alimentos' | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'sheets', 'uniforms-epis']" *ngIf="!settingsService.getBool('no-menu-sheets')">{{ 'Uniformes e EPIs' | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'projects']" *ngIf="!settingsService.getBool('no-menu-projects')">{{ 'Pastas' | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'categories', 'cargos']" *ngIf="!settingsService.getBool('no-menu-categories')">{{ 'Cargos' | translate}}</a>
            <a class="dropdown-item not-role-4" [routerLink]="['/clients', clientId, 'categories', 'segmentos']" *ngIf="!settingsService.getBool('no-menu-categories')">{{ 'Segmentos' | translate}}</a>
            <a class="dropdown-item not-role-4 hide-app-inactive" [routerLink]="['/clients', clientId, 'users', 'clients']" *ngIf="!settingsService.getBool('no-menu-users-clients')">{{'Acesso do Cliente' | translate}}</a>
        </div>
    </li>
</ul> 