import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authService: AuthService,
        protected toastrService: ToastrService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401 && err.error && err.error.message && err.error.message === 'unauthorized-signature-required') {
                this.toastrService.error('Recurso exclusivo para assinantes!');
                this.router.navigate(['/payments/sign']);
                return;
            } else if (err.status === 401) {
                this.toastrService.error('Necessário estar logado');
                this.authService.removeStorageCurrentUser();
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
            }

            console.log(err);

            const error: {error: boolean, message: string, status: number, validation_errors?: any, original_message?: string } = {
                error: true,
                message: (err.statusText ? err.statusText : 'Falha na comunicação com o servidor. Por favor, tente mais tarde.'),
                status: err.status
            };
            if (err.error) {
                if (err.error.messages) {
                    error.message = err.error.messages;
                }
                if (err.error.message) {
                    error.message = err.error.message;
                }
                if (err.error.validation_errors) {
                    error.validation_errors = err.error.validation_errors;
                }
                if (err.error.original_message) {
                    error.original_message = err.error.original_message;
                }
            }

            if (error.message === 'Unknown Error' && err.message) {
                console.clear();
                console.warn(err.message, err);
                if (!navigator.onLine) {
                    error.message = 'Não conectado à internet.';
                } else if (err.message.startsWith('Http failure response')) {
                    error.message = 'Erro de comunicação com o servidor. Por favor, tente novamente mais tarde.';
                }
            }

            return throwError(error);
        }));
    }
}
