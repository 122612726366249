import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { Base64 } from 'js-base64';
import * as Sentry from "@sentry/angular";
import Lara from '@primeng/themes/lara';
import ptBrLocale from 'primelocale/pt-BR.json';

import { HttpLoaderFactory } from './app/app.module';
import { environment } from './environments/environment';
import { ErrorsInterceptor } from './app/shared/interceptors/errors.interceptor';
import { JwtInterceptor } from './app/shared/interceptors/jwt.interceptor';
import { LoaderInterceptor } from './app/shared/interceptors/loader.interceptor';
import { VersionCheckService } from './app/shared/services/version-check.service';
import { CoreModule } from './app/core/core.module';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';

const localeId = localStorage.getItem('language') ? localStorage.getItem('language').replace('_', '-') : 'pt-BR';
const ptBR = ptBrLocale["pt-BR"];

const getCurrentUser = () => {
    try {
        const userStr = localStorage.getItem('currentUser');
        if (userStr) {
            const user = JSON.parse(userStr);
            return {
                email: Base64.decode(user.email),
            };
        }
        return null;
    } catch {
        return null;
    }
};

Sentry.init({
    dsn: 'https://ca5e613efff2aae58dcdf059207e2906@o4509080723783680.ingest.us.sentry.io/4509080726994944',
    enabled: environment.production,
    environment: environment.production ? 'production' : 'development',
    beforeSend(event) {
        if (event.exception) {
            const error = event.exception.values[0];
            if (error.type === 'TypeError') {
                if (error.value.includes('undefined (reading')) {
                    const prop = error.value.match(/reading '(.+)'/)?.[1];
                    error.type = `Propriedade Indefinida: ${prop}`;
                } else if (error.value.includes('null (reading')) {
                    const prop = error.value.match(/reading '(.+)'/)?.[1];
                    error.type = `Propriedade Nula: ${prop}`;
                } else if (error.value.includes('is not a function')) {
                    const func = error.value.match(/(.+) is not a function/)?.[1];
                    error.type = `Função Inválida: ${func}`;
                } else if (error.value.includes('is not a valid')) {
                    error.type = `Tipo Inválido: ${error.value}`;
                }
            }
        }

        const user = getCurrentUser();
        if (user) {
            event.user = user;
        }
        return event;
    },
    initialScope: {
        user: getCurrentUser(),
        tags: {
            // version: environment.version || '1.0.0',
            locale: localeId,
            // platform: 'web'
        }
    },
    tracesSampleRate: environment.production ? 0.2 : 1.0,
    ignoreErrors: [
        /tinymce/,
        /service-worker\.js/,
        /assets\/js\/(app|pwa)\.js/,
    ],
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            CoreModule,
            AppRoutingModule,
            ToastrModule.forRoot(),
            TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'pt_BR'
        })),
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
        { provide: Sentry.TraceService, deps: [Router] },
        { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: localeId },
        VersionCheckService,
        provideIonicAngular({ mode: 'ios' }),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
        providePrimeNG({
            translation: ptBR,
            theme: {
                preset: Lara,
                options: {
                    darkModeSelector: 'none'
                },
            },
        }),
    ]
})
  .catch(err => console.error(err));
