import { Component, Input, TemplateRef } from '@angular/core';
import { NgIf } from '@angular/common';
import { Device } from '@capacitor/device';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { NotificationsComponent } from '../../notifications/notifications.component';
import { SyncStatusService } from '../../../../../shared/services/sync-status.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { QrCodeComponent } from '../qr-code/qr-code.component';

@Component({
    selector: 'app-user-nav',
    templateUrl: './user-nav.component.html',
    styleUrls: ['./user-nav.component.css'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        TranslateModule,
        NotificationsComponent,
        QrCodeComponent
    ],
    providers: [
        BsModalService
    ]
})
export class UserNavComponent {

    @Input() userPhoto: string = '';
    @Input() userName: string = '';
    @Input() userId: number;

    public appVersion: string;
    public appHash: string;
    public appBuildDate: string;
    public currentYear: number = new Date().getFullYear();
    public deviceInfo: any = {
        model: '',
        platform: '',
        version: '',
        manufacturer: '',
        isVirtual: false,
        osVersion: '',
        webViewVersion: '',
        isPWA: false,
        battery: {
            batteryLevel: 0,
            isCharging: false
        },
        id: {
            identifier: ''
        }
    };

    private modalRef?: BsModalRef;

    constructor(
        public syncStatusService: SyncStatusService,
        private authService: AuthService,
        private toastrService: ToastrService,
        private router: Router,
        private modalService: BsModalService,
    ) {}

    public logout() {
        this.authService.logout().subscribe(
            () => {
                this.authService.removeStorageCurrentUser();
                this.router.navigate([ '/login' ]);
                this.toastrService.success('Logout realizado com sucesso!');
            },
            error => {
                this.authService.removeStorageCurrentUser();
                this.router.navigate([ '/login' ]);
                console.log(error);
            });
    }

    public async showAboutModal(template: TemplateRef<any>): Promise<void> {

        this.appVersion = '<i class="fa fa-spinner fa-spin"></i>';
        this.appHash = '<i class="fa fa-spinner fa-spin"></i>';
        this.appBuildDate = '<i class="fa fa-spinner fa-spin"></i>';

        this.modalRef = this.modalService.show(template, {
            class: 'modal-md',
            keyboard: true,
            backdrop: 'static'
        });

        try {

            const info = await Device.getInfo();
            const battery = await Device.getBatteryInfo();
            const id = await Device.getId();

            const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
                (window.navigator as any).standalone ||
                document.referrer.includes('android-app://');

            this.deviceInfo = {
                ...info,
                battery: battery,
                id: id,
                isPWA: isPWA
            };

            const response = await fetch('version.json');
            const data = await response.json();

            this.appVersion = data.version;
            this.appHash = data.hash;
            this.appBuildDate = new Date(data.buildDate).toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });

        } catch (error) {
            console.error('Erro ao carregar informações:', error);
            this.appVersion = '';
            this.appHash = '';
            this.appBuildDate = '';
        }
    }

    public hideModal(): void {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
}
