<ion-app>
    <ion-content class="main-app {{device}} {{app}} {{platform}}">
        <ion-refresher slot="fixed" [pullMin]="100" [pullMax]="200" (ionRefresh)="handleRefresh($event)">
            <ion-refresher-content
                pullingIcon="chevron-down-circle-outline"
                pullingText="Arraste para atualizar..."
                refreshingSpinner="lines"
                refreshingText="Atualizando..."
            ></ion-refresher-content>
        </ion-refresher>
        <router-outlet></router-outlet>
    </ion-content>
</ion-app>
