<ul class="nav navbar-nav navbar-user">
    <li class="nav-item nav-item-sync" style="display: none;">
        <a class="nav-link" href="javascript:;" title="Sincronizando registro offline...">
            <i class="fa fa-refresh fa-spin text-warning"></i>
        </a>
    </li>
    <li class="nav-item qr-code" *ngIf="userId == 2">
        <app-qr-code></app-qr-code>
    </li>
    <li class="nav-item dropdown nav-item-notifications">
        <app-notifications></app-notifications>
    </li>
    <li class="nav-item dropdown nav-item-user">
        <a class="nav-link user-link dropdown-link" href="#" id="userDropdown" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
            <img alt="" src="{{ userPhoto }}" class="user-photo rounded-circle"/>
            <span class="user-name ml-1">{{ userName }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <a class="dropdown-item" routerLink="/profile">{{ 'Perfil' | translate }} <i class="fa fa-user pull-right"></i></a>
            <a class="dropdown-item not-role-4" routerLink="/company">{{ 'Conta' | translate }} <i class="fa fa-building pull-right"></i></a>
            <a class="dropdown-item" routerLink="/settings">{{ 'Configurações' | translate }} <i class="fa fa-wrench pull-right"></i></a>
            <a class="dropdown-item not-role-4 only-not-app" routerLink="/users">{{ 'Usuários' | translate }} <i class="fa fa-group pull-right"></i></a>
            <a class="dropdown-item not-role-4 only-not-app" routerLink="/payments">{{ 'Faturamento' | translate }} <i class="fa fa-shopping-cart pull-right"></i></a>
            <a class="dropdown-item" routerLink="/notifications">{{ 'Notificações' | translate }} <i class="fa fa-comment pull-right"></i></a>
            <a class="dropdown-item" routerLink="/queues">{{ 'Sincronização' | translate }} <i class="fa fa-cloud-upload pull-right"></i></a>
            <a class="dropdown-item d-none" routerLink="/recommendations">{{ 'Indique & Ganhe' | translate }} <i class="fa fa-gift pull-right"></i></a>
            <a class="dropdown-item" routerLink="/podcasts">{{ 'Podcasts' | translate }} <i class="fa fa-volume-up pull-right"></i></a>
            <a class="dropdown-item" href="https://api.whatsapp.com/send?phone=5511963722475&text=Ol%C3%A1!%20Estou%20usando%20o%20Food%20Checker%20e%20preciso%20de%20suporte..." target="_blank" rel="external">{{ 'Suporte' | translate}}<i class="fa fa-comments pull-right"></i></a>
            <a class="dropdown-item only-not-app" routerLink="/page/termos-de-uso">{{ 'Termos de Uso' | translate }} <i class="fa fa-file-text pull-right"></i></a>
            <a *ngIf="userId == 2" class="dropdown-item" routerLink="/tests">{{ 'Testes' | translate }} <i class="fa fa-wrench pull-right"></i></a>
            <a *ngIf="userId == 2" class="dropdown-item" routerLink="/tests/ionic">{{ 'Testes Ionic' | translate }} <i class="fa fa-wrench pull-right"></i></a>
            <a class="dropdown-item" href="javascript:;" (click)="showAboutModal(aboutModalTemplate)">{{ 'Sobre' | translate }} <i class="fa fa-info-circle pull-right"></i></a>
            <div class="dropdown-divider d-none d-lg-block"></div>
            <a class="dropdown-item" (click)="logout()">{{ 'Sair' | translate }} <i class="fa fa-sign-out pull-right"></i></a>
        </div>
    </li>
</ul>

<!-- Modal About -->
<ng-template #aboutModalTemplate>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Sobre o Food Checker' | translate }}</h5>
        <button type="button" class="close" (click)="hideModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center mb-4">
            <img src="/assets/imgs/logo/logo.svg" alt="Food Checker" class="img-fluid" style="max-height: 50px;">
        </div>
        <div class="table-responsive">
            <table class="table table-sm">
                <tbody>
                <tr>
                    <th>{{ 'Versão' | translate }}:</th>
                    <td [innerHTML]="appHash"></td>
                </tr>
                <tr>
                    <th>{{ 'Data do Build' | translate }}:</th>
                    <td [innerHTML]="appBuildDate"></td>
                </tr>
                <tr>
                    <th>{{ 'Modelo' | translate }}:</th>
                    <td>{{ deviceInfo.model || '-' }}</td>
                </tr>
                <tr>
                    <th>{{ 'Plataforma' | translate }}:</th>
                    <td class="text-capitalize">{{ deviceInfo.platform || '-' }}{{ deviceInfo.isPWA ? ' - PWA' : '' }}</td>
                </tr>
                <tr>
                    <th>{{ 'Versão do Sistema' | translate }}:</th>
                    <td>{{ deviceInfo.osVersion || '-' }}</td>
                </tr>
                <tr>
                    <th>{{ 'Fabricante' | translate }}:</th>
                    <td class="text-capitalize">{{ deviceInfo.manufacturer || '-' }}</td>
                </tr>
                <tr>
                    <th>{{ 'Bateria' | translate }}:</th>
                    <td>
                        {{ deviceInfo.battery?.batteryLevel ? (deviceInfo.battery.batteryLevel * 100).toFixed(1) + '%' : '-' }}
                        <i class="fa fa-bolt" aria-hidden="true" *ngIf="deviceInfo.battery?.isCharging"></i>
                    </td>
                </tr>
                <tr>
                    <th>{{ 'ID do Dispositivo' | translate }}:</th>
                    <td>{{ deviceInfo.id?.identifier || '-' }}</td>
                </tr>
                <tr>
                    <th>{{ 'WebView' | translate }}:</th>
                    <td>{{ deviceInfo.webViewVersion || '-' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center mt-4">
            <p class="mb-0 text-muted small">
                {{ 'Copyright © ' + currentYear + ' Food Checker. Todos os direitos reservados.' | translate }}
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideModal()">{{ 'Fechar' | translate }}</button>
    </div>
</ng-template>
