import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-admin-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css'],
    standalone: true,
    imports: [CommonModule, RouterLink, TranslateModule]
})
export class AlertsComponent {
    @Input() registered: boolean = false;
    @Input() userName: string = '';
    @Input() isIos: boolean = false;
    @Input() isAndroid: boolean = false;
} 