import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Loader } from '../models/loader.model';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    loader: Loader;

    constructor(private loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.urlWithParams.includes('noLoader=true')) {
            return next.handle(request);
        }

        setTimeout(() => { this.loaderService.show(); }, 0);

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.loaderService.hide();
                    }
                },
                () => {
                    this.loaderService.hide();
                }));
    }
}
