<div class="public-body {{ pageClass }}">
    <router-outlet></router-outlet>
    <div class="text-center text-gray-light small only-is-app">
        app: 1.0.5
    </div>
</div>

<a href="https://api.whatsapp.com/send?phone=5511963722475&text=Digite%20sua%20d%C3%BAvida..."
   class="whatsapp-button" data-toggle="tooltip" data-placement="top" title="Podemos ajudar com alguma dúvida?"
   target="_blank" rel="external">
    <i class="fa fa-whatsapp"></i>
</a>
