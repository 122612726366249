<!-- Alerta de versão gratuita -->
<div class="alert alert-info alert-top not-subscriber-active hide-blackfriday hide-registered-successfully only-not-app"
     role="alert" *ngIf="!registered">
    <button type="button" class="close" data-dismiss="alert">×</button>
    Você esta utilizando uma versão gratuita do Food Checker. Alguns
    recursos são liberados apenas para
    <a routerLink="/payments/sign">assinantes</a>.
</div>

<!-- Alerta de boas-vindas -->
<div class="alert alert-info alert-top text-center not-subscriber-active hide-blackfriday hide-only-ios" role="alert"
     *ngIf="registered">
    <button type="button" class="close" data-dismiss="alert">×</button>
    Olá {{ userName.split(" ")[0] }}, está começando no Food Checker? Veja a
    demonstração de cada funcionalidade acessando:
    <a href="https://know.foodchecker.com.br?utm_source=app&utm_medium=dashboard&utm_id=demonstracao"
       target="_blank">https://know.foodchecker.com.br</a>.
</div>

<!-- Alerta de troca de cartão -->
<div class="alert alert-danger alert-top alert-subscriber-payment_method_change only-not-app" role="alert">
    <button type="button" class="close" data-dismiss="alert">×</button>
    <strong>Alerta!</strong> É necessário realizar a troca do seu cartão de
    crédito. <a routerLink="/payments">Clique aqui</a>, para realizar a
    troca.
</div>

<!-- Alerta de assinatura cancelada -->
<div class="alert alert-danger alert-top alert-subscriber-cancelled only-not-app" role="alert">
    <button type="button" class="close" data-dismiss="alert">×</button>
    <strong>Alerta!</strong> Sua assinatura foi cancelada.<br/>
    <a routerLink="/payments">Clique aqui</a>, para acessar as configurações
    de pagamento.
</div>

<!-- Alerta de permissão de câmera -->
<div class="alert alert-danger alert-top permission-camera-alert" role="alert" style="display: none;">
    <button type="button" class="close" data-dismiss="alert">×</button>
    <strong>Erro ao acessar a câmera. Verifique se a permissão de acesso à câmera foi concedida.</strong><br/>
    <div *ngIf="isIos">
        <ol class="mb-0">
            <li>Vá para "Configurações" no seu dispositivo.</li>
            <li>Role para baixo e selecione "Privacidade".</li>
            <li>Selecione "Câmera".</li>
            <li>Ative a opção para permitir o acesso à câmera para o Food Checker.</li>
        </ol>
    </div>
    <div *ngIf="isAndroid">
        <ol class="mb-0">
            <li>Clique e segure no ícone do Food Checker no seu dispositivo.</li>
            <li>Clique em "Configurações do site".</li>
            <li>Ative a opção para permitir o acesso à câmera.</li>
        </ol>
    </div>
</div> 