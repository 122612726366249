import { Component } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';

import { AuthService } from '../../shared/services/auth.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-help-layout',
    templateUrl: './help-layout.component.html',
    styleUrls: ['./help-layout.component.css'],
    imports: [RouterLink, NgIf, RouterOutlet]
})
export class HelpLayoutComponent {

    public showTickets = false;

    constructor (
        private router: Router,
        private authService: AuthService
    ) {
        if (localStorage.getItem('situation') === 'ACTIVE') {
            this.showTickets = this.authService.currentUserValue.account.tickets; // true
        }
    }

    public goArticles() {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(
            () => this.router.navigate(['/help'])
        );
    }

    public goTickets() {
        this.router.navigateByUrl('/help', {skipLocationChange: true}).then(
            () => this.router.navigate(['/help/tickets'])
        );
    }
}
