import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({ declarations: [],
    exports: [
        BrowserModule,
        BrowserAnimationsModule,
    ], imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule
    ], providers: [
        Title
    ] })
export class CoreModule { }
