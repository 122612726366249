import { Injectable } from '@angular/core';
import { RoutesService } from '../services/routes.service';

@Injectable({
    providedIn: 'root'
})
export class RoutesData {

    constructor(private routesService: RoutesService) {

        // tslint:disable:max-line-length

        this.routesService.addItem('/', 'Home', 'Home', null, false);

        this.routesService.addItem('/login', 'Login', 'Login', '/', false);
        this.routesService.addItem('/login/forgot', 'Lembrar Senha', 'Lembrar Senha', '/login', false);
        this.routesService.addItem('/login/password/*', 'Nova Senha', 'Nova Senha', '/login', false);
        this.routesService.addItem('/login/register', 'Cadastre-se', 'Cadastre-se', '/login', false);
        this.routesService.addItem('/login/activate/*', 'Ativar Cadastro', 'Ativar Cadastro', '/login', false);

        this.routesService.addItem('/clients', 'Clientes', 'Meus Clientes', '/', false);
        this.routesService.addItem('/clients/new', 'Novo', 'Novo Cliente', '/clients', false);

        this.routesService.addItem('/providers', 'Fornecedores', 'Fornecedores', null, false);
        this.routesService.addItem('/providers/new', 'Novo', 'Novo Fornecedor', '/providers', false);
        this.routesService.addItem('/providers/[0-9]/edit', 'Editar', 'Editar Fornecedor', '/providers', false);
        this.routesService.addItem('/providers/category/[0-9]', 'Fornecedores', 'Fornecedores', null, false);

        this.routesService.addItem('/users', 'Usuários', 'Usuários', null, false);
        this.routesService.addItem('/users/new', 'Novo', 'Novo Usuário', '/users', false);
        this.routesService.addItem('/users/[0-9]/edit', 'Editar', 'Editar Usuário', '/users', false);
        this.routesService.addItem('/users/[0-9]/password', 'Senha', 'Alterar Senha', '/users', false);

        this.routesService.addItem('/calendars', 'Agenda', 'Agenda', null, false);

        this.routesService.addItem('/scrum', 'Planos de Ação', 'Painel de Planos de Ação', null, false);

        this.routesService.addItem('/harvests', 'Safras', 'Safras', null, false);

        this.routesService.addItem('/legislations', 'Legislações', 'Legislações', null, false);

        this.routesService.addItem('/connections', 'Conexões', 'Conexões', null, false);
        this.routesService.addItem('/connections/consultants', 'Consultores', 'Consultores', '/connections', false);
        this.routesService.addItem('/connections/consultants/[0-9]/view', 'Visualizar', 'Visualizar Consultores', '/connections/consultants', false);
        this.routesService.addItem('/connections/consultants/my', 'Perfil Profissional', 'Perfil Profissional', '/connections', false);
        this.routesService.addItem('/connections/jobs', 'Vagas', 'Vagas', '/connections', false);
        this.routesService.addItem('/connections/jobs/my', 'Vagas', 'Minhas Vagas', '/connections', false);
        this.routesService.addItem('/connections/jobs/[0-9]/view', 'Visualizar', 'Visualizar Vagas', '/connections/jobs', false);
        this.routesService.addItem('/connections/jobs/new', 'Nova', 'Nova Vaga', '/connections/jobs', false);
        this.routesService.addItem('/connections/messages/[0-9]/view', 'Mensagens', 'Mensagens de Conexão', '/connections', false);

        this.routesService.addItem('/podcasts', 'Checker Casts', 'Checker Casts', '/', false);

        this.routesService.addItem('/recommendations', 'Indique e Ganhe', 'Indique e Ganhe', null, false);

        this.routesService.addItem('/notifications', 'Notificações', 'Notificações', '/', false);
        this.routesService.addItem('/notifications/[0-9]/view', 'Visualizar', 'Visualizar Notificação', '/notifications', false);

        this.routesService.addItem('/payments', 'Faturamento', 'Faturamento', null, false);
        this.routesService.addItem('/payments/sign', 'Planos', 'Planos', '/payments', false);
        this.routesService.addItem('/payments/method', 'Método de Pagamento', 'Método de Pagamento', '/payments', false);
        this.routesService.addItem('/payments/user', 'Usuários Adicionais', 'Usuários Adicionais', '/payments', false);

        this.routesService.addItem('/settings', 'Configurações', 'Configurações', null, false);

        this.routesService.addItem('/queues', 'Sincronização', 'Sincronização', null, false);

        this.routesService.addItem('/profile', 'Configurações Perfil', 'Configurações Perfil', null, false);
        this.routesService.addItem('/company', 'Configurações Empresa', 'Configurações Empresa', null, false);

        this.routesService.addItem('/page/termos-de-uso', 'Termos de Uso', 'Termos de Uso', '/', false);

        this.routesService.addItem('/help', 'Ajuda', 'Ajuda', '/', false);
        this.routesService.addItem('/help/article', 'Ajuda', 'Ajuda', '/', false);
        this.routesService.addItem('/help/category', 'Ajuda', 'Ajuda', '/', false);
        this.routesService.addItem('/help/tickets', 'Meus Chamados', 'Meus Chamados', '/help/tickets', false);
        this.routesService.addItem('/help/tickets/new', 'Ajuda', 'Novo Chamado', '/help/tickets', false);
        this.routesService.addItem('/support/tickets/new', 'Ajuda', 'Novo Ticket', '/support/tickets', false);
        this.routesService.addItem('/help/tickets/[0-9]/edit', 'Ajuda', 'Chamado', '/help/tickets', false);

        /* SUBMENU CLIENTS */
        this.routesService.addItem('/clients/[0-9]/view', 'Início', 'Cliente', null, true);
        this.routesService.addItem('/clients/[0-9]/edit', 'Editar', 'Editar Cliente', null, true);

        this.routesService.addItem('/clients/[0-9]/checklists', 'Modelos de Checklists', 'Modelos de Checklists', null, true);
        this.routesService.addItem('/clients/[0-9]/checklists/archive', 'Checklists Arquivados', 'Modelos de Checklists Arquivados', null, true);
        this.routesService.addItem('/clients/[0-9]/checklists/performeds', 'Checklists', 'Checklists Aplicados', null, true);
        this.routesService.addItem('/clients/[0-9]/checklists/new', 'Novo', 'Novo Checklist', '/clients/[0-9]/checklists', true);
        this.routesService.addItem('/clients/[0-9]/checklists/[0-9]/view', 'Visualizar', 'Visualizar Checklist', '/clients/[0-9]/checklists', true);
        this.routesService.addItem('/clients/[0-9]/checklists/[0-9]/edit', 'Editar', 'Editar Checklist', '/clients/[0-9]/checklists', true);
        this.routesService.addItem('/clients/[0-9]/checklists/[0-9]/perform', 'Aplicar checklist', 'Checklist', '/clients/[0-9]/checklists/performeds', true);
        this.routesService.addItem('/clients/[0-9]/checklists/[0-9]/performed/view', 'Visualizar', 'Checklist Aplicado', '/clients/[0-9]/checklists/performeds', true);
        this.routesService.addItem('/clients/[0-9]/checklists/[0-9]/performed/edit', 'Aplicar', 'Aplicar Checklist', '/clients/[0-9]/checklists/performeds', true);

        this.routesService.addItem('/clients/[0-9]/forms', 'Modelos de Planilhas', 'Modelos de Planilhas', null, true);
        this.routesService.addItem('/clients/[0-9]/forms/archive', 'Planilhas Arquivadas', 'Modelos de Planilhas Arquivadas', null, true);
        this.routesService.addItem('/clients/[0-9]/forms/performeds', 'Planilhas', 'Planilhas Aplicadas', null, true);
        this.routesService.addItem('/clients/[0-9]/forms/new', 'Novo', 'Nova Planilha', '/clients/[0-9]/forms', true);
        this.routesService.addItem('/clients/[0-9]/forms/[0-9]/view', 'Visualizar', 'Visualizar Planilha', '/clients/[0-9]/forms', true);
        this.routesService.addItem('/clients/[0-9]/forms/[0-9]/edit', 'Editar', 'Editar Planilha', '/clients/[0-9]/forms', true);
        this.routesService.addItem('/clients/[0-9]/forms/[0-9]/perform', 'Aplicar Planilha', 'Planilha', '/clients/[0-9]/forms/performeds', true);
        this.routesService.addItem('/clients/[0-9]/forms/[0-9]/performed/view', 'Visualizar', 'Planilha Aplicada', '/clients/[0-9]/forms/performeds', true);
        this.routesService.addItem('/clients/[0-9]/forms/[0-9]/performed/edit', 'Aplicar', 'Aplicar Planilha', '/clients/[0-9]/forms/performeds', true);

        this.routesService.addItem('/clients/[0-9]/action-plans', 'Planos de Ação', 'Planos de Ação', null, true);
        this.routesService.addItem('/clients/[0-9]/action-plans/[0-9]/checklist', 'Planos de Ação', 'Planos de Ação', null, true);
        this.routesService.addItem('/clients/[0-9]/action-plans/new', 'Novo', 'Novo Plano de Ação', '/clients/[0-9]/action-plans', true);
        this.routesService.addItem('/clients/[0-9]/action-plans/[0-9]/view', 'Visualizar', 'Visualizar Plano de Ação', '/clients/[0-9]/action-plans', true);
        this.routesService.addItem('/clients/[0-9]/action-plans/[0-9]/edit', 'Editar', 'Editar Plano de Ação', '/clients/[0-9]/action-plans', true);

        this.routesService.addItem('/clients/[0-9]/scrum', 'Planos de Ação', 'Painel de Planos de Ação', null, true);

        this.routesService.addItem('/clients/[0-9]/documents', 'Documentos', 'Documentos', null, true);
        this.routesService.addItem('/clients/[0-9]/documents/new', 'Novo', 'Novo Documento', '/clients/[0-9]/documents', true);
        this.routesService.addItem('/clients/[0-9]/documents/[0-9]/view', 'Visualizar', 'Visualizar Documento', '/clients/[0-9]/documents', true);
        this.routesService.addItem('/clients/[0-9]/documents/[0-9]/edit', 'Editar', 'Editar Documento', '/clients/[0-9]/documents', true);

        this.routesService.addItem('/clients/[0-9]/projects', 'Pastas', 'Pastas', null, true);

        this.routesService.addItem('/clients/[0-9]/visits', 'Visitas', 'Ata de Visitas', null, true);
        this.routesService.addItem('/clients/[0-9]/visits/new', 'Visita', 'Ata de Visita', '/clients/[0-9]/visits', true);
        this.routesService.addItem('/clients/[0-9]/visits/[0-9]/view', 'Visualizar', 'Visualizar Ata de Visita', '/clients/[0-9]/visits', true);
        this.routesService.addItem('/clients/[0-9]/visits/[0-9]/edit', 'Visita', 'Ata de Visita', '/clients/[0-9]/visits', true);

        this.routesService.addItem('/clients/[0-9]/sops', 'POP\'s - IT\'s', 'POP\'s - IT\'s', null, true);
        this.routesService.addItem('/clients/[0-9]/sops/new', 'POP - IT', 'POP - IT', '/clients/[0-9]/sops', true);
        this.routesService.addItem('/clients/[0-9]/sops/[0-9]/view', 'Visualizar', 'Visualizar POP - IT', '/clients/[0-9]/sops', true);
        this.routesService.addItem('/clients/[0-9]/sops/[0-9]/edit', 'POP - IT', 'POP - IT', '/clients/[0-9]/sops', true);

        this.routesService.addItem('/clients/[0-9]/sops/items', 'Modelos de POP\'s - IT\'s', 'Modelos de POP\'s - IT\'s', null, true);
        this.routesService.addItem('/clients/[0-9]/sops/items/archive', 'Arquivados', 'Arquivados', '/clients/[0-9]/sops/items', true);
        this.routesService.addItem('/clients/[0-9]/sops/item/new', 'Novo', 'Novo Modelo de POP - IT', '/clients/[0-9]/sops/items', true);
        this.routesService.addItem('/clients/[0-9]/sops/item/[0-9]/view', 'Visualizar', 'Visualizar Modelo de POP - IT', '/clients/[0-9]/sops/items', true);
        this.routesService.addItem('/clients/[0-9]/sops/item/[0-9]/edit', 'Editar', 'Editar Modelo de POP - IT', '/clients/[0-9]/sops/items', true);

        this.routesService.addItem('/clients/[0-9]/trainings', 'Treinamentos', 'Treinamentos', null, true);
        this.routesService.addItem('/clients/[0-9]/trainings/new', 'Treinamento', 'Treinamento', '/clients/[0-9]/trainings', true);
        this.routesService.addItem('/clients/[0-9]/trainings/[0-9]/view', 'Visualizar', 'Visualizar Treinamento', '/clients/[0-9]/trainings', true);
        this.routesService.addItem('/clients/[0-9]/trainings/[0-9]/edit', 'Treinamento', 'Treinamento', '/clients/[0-9]/trainings', true);

        this.routesService.addItem('/clients/[0-9]/sheets/foods', 'Alimentos', 'Alimentos', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/foods/new', 'Alimentos', 'Alimentos', '/clients/[0-9]/sheets/foods', true);
        this.routesService.addItem('/clients/[0-9]/sheets/foods/[0-9]/view', 'Alimentos', 'Alimentos', '/clients/[0-9]/sheets/foods', true);
        this.routesService.addItem('/clients/[0-9]/sheets/foods/[0-9]/edit', 'Alimentos', 'Alimentos', '/clients/[0-9]/sheets/foods', true);
        this.routesService.addItem('/clients/[0-9]/sheets/equipments', 'Equipamentos', 'Equipamentos', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/equipments/new', 'Equipamentos', 'Equipamentos', '/clients/[0-9]/sheets/equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/equipments/[0-9]/view', 'Equipamentos', 'Equipamentos', '/clients/[0-9]/sheets/equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/equipments/[0-9]/edit', 'Equipamentos', 'Equipamentos', '/clients/[0-9]/sheets/equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/uniforms-epis', 'Uniformes e EPIs', 'Uniformes e EPIs', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/uniforms-epis/new', 'Uniformes e EPIs', 'Uniformes e EPIs', '/clients/[0-9]/sheets/uniforms-epis', true);
        this.routesService.addItem('/clients/[0-9]/sheets/uniforms-epis/[0-9]/view', 'Uniformes e EPIs', 'Uniformes e EPIs', '/clients/[0-9]/sheets/uniforms-epis', true);
        this.routesService.addItem('/clients/[0-9]/sheets/uniforms-epis/[0-9]/edit', 'Uniformes e EPIs', 'Uniformes e EPIs', '/clients/[0-9]/sheets/uniforms-epis', true);

        this.routesService.addItem('/clients/[0-9]/sheets/temperature-foods', 'Planilhas', 'Temperatura dos Alimentos', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/temperature-foods/new', 'Temperatura dos Alimentos', 'Temperatura dos Alimentos', '/clients/[0-9]/sheets/temperature-foods', true);
        this.routesService.addItem('/clients/[0-9]/sheets/temperature-foods/[0-9]/view', 'Temperatura dos Alimentos', 'Temperatura dos Alimentos', '/clients/[0-9]/sheets/temperature-foods', true);
        this.routesService.addItem('/clients/[0-9]/sheets/temperature-foods/[0-9]/edit', 'Temperatura dos Alimentos', 'Temperatura dos Alimentos', '/clients/[0-9]/sheets/temperature-foods', true);
        this.routesService.addItem('/clients/[0-9]/sheets/temperature-equipments', 'Planilhas', 'Temperatura dos Equipamentos', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/temperature-equipments/new', 'Temperatura dos Equipamentos', 'Temperatura dos Equipamentos', '/clients/[0-9]/sheets/temperature-equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/temperature-equipments/[0-9]/view', 'Temperatura dos Equipamentos', 'Temperatura dos Equipamentos', '/clients/[0-9]/sheets/temperature-equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/temperature-equipments/[0-9]/edit', 'Temperatura dos Equipamentos', 'Temperatura dos Equipamentos', '/clients/[0-9]/sheets/temperature-equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-equipments', 'Planilhas', 'Limpeza dos Equipamentos', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-equipments/new', 'Limpeza do Equipamentos', 'Limpeza do Equipamentos', '/clients/[0-9]/sheets/cleanings-equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-equipments/[0-9]/view', 'Limpeza dos Equipamentos', 'Limpeza dos Equipamentos', '/clients/[0-9]/sheets/cleanings-equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-equipments/[0-9]/edit', 'Limpeza dos Equipamentos', 'Limpeza dos Equipamentos', '/clients/[0-9]/sheets/cleanings-equipments', true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-installations', 'Planilhas', 'Limpeza das Instalações', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-installations/new', 'Limpeza das Instalações', 'Limpeza das Instalações', '/clients/[0-9]/sheets/cleanings-installations', true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-installations/[0-9]/view', 'Limpeza das Instalações', 'Limpeza das Instalações', '/clients/[0-9]/sheets/cleanings-installations', true);
        this.routesService.addItem('/clients/[0-9]/sheets/cleanings-installations/[0-9]/edit', 'Limpeza das Instalações', 'Limpeza das Instalações', '/clients/[0-9]/sheets/cleanings-installations', true);
        this.routesService.addItem('/clients/[0-9]/sheets/oil-controls', 'Planilhas', 'Controle de Óleos', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/oil-controls/new', 'Controle de Óleos', 'Controle de Óleos', '/clients/[0-9]/sheets/oil-controls', true);
        this.routesService.addItem('/clients/[0-9]/sheets/oil-controls/[0-9]/view', 'Controle de Óleos', 'Controle de Óleos', '/clients/[0-9]/sheets/oil-controls', true);
        this.routesService.addItem('/clients/[0-9]/sheets/oil-controls/[0-9]/edit', 'Controle de Óleos', 'Controle de Óleos', '/clients/[0-9]/sheets/oil-controls', true);
        this.routesService.addItem('/clients/[0-9]/sheets/wastes', 'Planilhas', 'Controle de Desperdícios', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/wastes/new', 'Controle de Desperdícios', 'Controle de Desperdícios', '/clients/[0-9]/sheets/wastes', true);
        this.routesService.addItem('/clients/[0-9]/sheets/wastes/[0-9]/view', 'Controle de Desperdícios', 'Controle de Desperdícios', '/clients/[0-9]/sheets/wastes', true);
        this.routesService.addItem('/clients/[0-9]/sheets/wastes/[0-9]/edit', 'Controle de Desperdícios', 'Controle de Desperdícios', '/clients/[0-9]/sheets/wastes', true);
        this.routesService.addItem('/clients/[0-9]/sheets/receiving-products', 'Planilhas', 'Recebimento de Mercadoria', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/receiving-products/new', 'Recebimento de Mercadoria', 'Recebimento de Mercadoria', '/clients/[0-9]/sheets/receiving-products', true);
        this.routesService.addItem('/clients/[0-9]/sheets/receiving-products/[0-9]/view', 'Recebimento de Mercadoria', 'Recebimento de Mercadoria', '/clients/[0-9]/sheets/receiving-products', true);
        this.routesService.addItem('/clients/[0-9]/sheets/receiving-products/[0-9]/edit', 'Recebimento de Mercadoria', 'Recebimento de Mercadoria', '/clients/[0-9]/sheets/receiving-products', true);
        this.routesService.addItem('/clients/[0-9]/sheets/alizarol-tests', 'Planilhas', 'Testes de Alizarol', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/alizarol-tests/new', 'Testes de Alizarol', 'Testes de Alizarol', '/clients/[0-9]/sheets/alizarol-tests', true);
        this.routesService.addItem('/clients/[0-9]/sheets/alizarol-tests/[0-9]/view', 'Testes de Alizarol', 'Testes de Alizarol', '/clients/[0-9]/sheets/alizarol-tests', true);
        this.routesService.addItem('/clients/[0-9]/sheets/alizarol-tests/[0-9]/edit', 'Testes de Alizarol', 'Testes de Alizarol', '/clients/[0-9]/sheets/alizarol-tests', true);
        this.routesService.addItem('/clients/[0-9]/sheets/fosfatase-tests', 'Planilhas', 'Testes de Fosfatase', null, true);
        this.routesService.addItem('/clients/[0-9]/sheets/fosfatase-tests/new', 'Testes de Fosfatase', 'Testes de Fosfatase', '/clients/[0-9]/sheets/fosfatase-tests', true);
        this.routesService.addItem('/clients/[0-9]/sheets/fosfatase-tests/[0-9]/view', 'Testes de Fosfatase', 'Testes de Fosfatase', '/clients/[0-9]/sheets/fosfatase-tests', true);
        this.routesService.addItem('/clients/[0-9]/sheets/fosfatase-tests/[0-9]/edit', 'Testes de Fosfatase', 'Testes de Fosfatase', '/clients/[0-9]/sheets/fosfatase-tests', true);

        this.routesService.addItem('/clients/[0-9]/contracts', 'Contratos', 'Contratos', null, true);
        this.routesService.addItem('/clients/[0-9]/contracts/new', 'Novo', 'Novo Contrato', '/clients/[0-9]/contracts', true);
        this.routesService.addItem('/clients/[0-9]/contracts/[0-9]/view', 'Visualizar', 'Visualizar', '/clients/[0-9]/contracts', true);
        this.routesService.addItem('/clients/[0-9]/contracts/[0-9]/edit', 'Editar', 'Editar', '/clients/[0-9]/contracts', true);

        this.routesService.addItem('/clients/[0-9]/proposals', 'Propostas', 'Propostas', null, true);
        this.routesService.addItem('/clients/[0-9]/proposals/new', 'Nova', 'Nova Proposta', '/clients/[0-9]/proposals', true);
        this.routesService.addItem('/clients/[0-9]/proposals/[0-9]/view', 'Visualizar', 'Visualizar', '/clients/[0-9]/proposals', true);
        this.routesService.addItem('/clients/[0-9]/proposals/[0-9]/edit', 'Editar', 'Editar', '/clients/[0-9]/proposals', true);

        // this.routesService.addItem('/clients/[0-9]/charges', 'Cobranças', 'Cobranças', null, true);
        // this.routesService.addItem('/clients/[0-9]/charges/new', 'Nova', 'Nova Cobrança', '/clients/[0-9]/charges', true);
        // this.routesService.addItem('/clients/[0-9]/charges/[0-9]/view', 'Visualizar', 'Visualizar', '/clients/[0-9]/charges', true);
        // this.routesService.addItem('/clients/[0-9]/charges/[0-9]/edit', 'Editar', 'Editar', '/clients/[0-9]/charges', true);

        this.routesService.addItem('/clients/[0-9]/ingredients/prices', 'Fichas Técnicas', 'Fichas Técnicas', null, true);
        this.routesService.addItem('/clients/[0-9]/ingredients', 'Meus Ingredientes', 'Meus Ingredientes', null, false);
        this.routesService.addItem('/clients/[0-9]/ingredients/search', 'Pesquisar Ingredientes', 'Pesquisar Ingredientes', '/', false);
        this.routesService.addItem('/clients/[0-9]/ingredients/new', 'Novo', 'Novo Ingrediente', '/clients/[0-9]/ingredients/search', false);
        this.routesService.addItem('/clients/[0-9]/ingredients/[0-9]/view', 'Visualizar', 'Visualizar Ingrediente', '/clients/[0-9]/ingredients/search', false);
        this.routesService.addItem('/clients/[0-9]/ingredients/[0-9]/generate', 'Gerar', 'Gerar Ingrediente', '/clients/[0-9]/ingredients/search', false);
        this.routesService.addItem('/clients/[0-9]/ingredients/[0-9]/edit', 'Editar', 'Editar Ingrediente', '/clients/[0-9]/ingredients/search', false);

        this.routesService.addItem('/clients/[0-9]/recipes', 'Receitas', 'Receitas', null, true);
        this.routesService.addItem('/clients/[0-9]/recipes/new', 'Nova', 'Nova Receita', '/clients/[0-9]/recipes', true);
        this.routesService.addItem('/clients/[0-9]/recipes/[0-9]/view', 'Visualizar', 'Receita', '/clients/[0-9]/recipes', true);
        this.routesService.addItem('/clients/[0-9]/recipes/[0-9]/edit', 'Editar', 'Editar Receita', '/clients/[0-9]/recipes', true);

        this.routesService.addItem('/clients/[0-9]/labels', 'Inf. Nutricionais', 'Informações Nutricionais', null, true);
        this.routesService.addItem('/clients/[0-9]/labels/[0-9]/new', 'Nova', 'Nova Informação Nutricional', '/clients/[0-9]/labels', true);
        this.routesService.addItem('/clients/[0-9]/labels/[0-9]/view', 'Visualizar', 'Informação Nutricional', '/clients/[0-9]/labels', true);
        this.routesService.addItem('/clients/[0-9]/labels/[0-9]/edit', 'Editar', 'Editar Informação Nutricional', '/clients/[0-9]/labels', true);
        this.routesService.addItem('/clients/[0-9]/labels/[0-9]/view/*', 'Inf. Nutricional', 'Informação Nutricional', '/clients/[0-9]/labels', true);

        this.routesService.addItem('/clients/[0-9]/datasheets', 'Fichas Técnicas', 'Fichas Técnicas', null, true);
        this.routesService.addItem('/clients/[0-9]/datasheets/[0-9]/new', 'Nova', 'Nova Ficha Técnica', '/clients/[0-9]/datasheets', true);
        this.routesService.addItem('/clients/[0-9]/datasheets/[0-9]/view', 'Visualizar', 'Ficha Técnica', '/clients/[0-9]/datasheets', true);
        this.routesService.addItem('/clients/[0-9]/datasheets/[0-9]/edit', 'Editar', 'Editar Ficha Técnica', '/clients/[0-9]/datasheets', true);
        this.routesService.addItem('/clients/[0-9]/datasheets/[0-9]/view/*', 'Ficha Técnica', 'Ficha Técnica', '/clients/[0-9]/datasheets', true);
        this.routesService.addItem('/clients/[0-9]/packagings', 'Fichas Técnicas', 'Fichas Técnicas', null, true);

        this.routesService.addItem('/clients/[0-9]/contacts', 'Equipe', 'Equipe', null, true);
        this.routesService.addItem('/clients/[0-9]/contacts/new', 'Nova Pessoa', 'Nova Pessoa', '/clients/[0-9]/contacts', true);
        this.routesService.addItem('/clients/[0-9]/contacts/[0-9]/edit', 'Editar Pessoa', 'Editar Pessoa', '/clients/[0-9]/contacts', true);

        this.routesService.addItem('/clients/[0-9]/reports', 'Relatórios', 'Relatórios', null, true);
        this.routesService.addItem('/clients/[0-9]/reports/*', 'Relatórios', 'Relatórios', null, true);

        this.routesService.addItem('/clients/[0-9]/users/clients', 'Acesso do Cliente', 'Acesso do Cliente', null, true);
        this.routesService.addItem('/clients/[0-9]/users/clients/[0-9]/new', 'Novo', 'Novo Acesso do Cliente', '/clients/[0-9]/users/clients', true);
        this.routesService.addItem('/clients/[0-9]/users/clients/[0-9]/view', 'Visualizar', 'Acesso do Cliente', '/clients/[0-9]/users/clients', true);
        this.routesService.addItem('/clients/[0-9]/users/clients/[0-9]/edit', 'Editar', 'Editar Acesso do Cliente', '/clients/[0-9]/users/clients', true);
        this.routesService.addItem('/clients/[0-9]/users/clients/[0-9]/view/*', 'Acesso do Cliente', 'Acesso do Cliente', '/clients/[0-9]/users/clients', true);

        this.routesService.addItem('/coming-soon', 'Em Breve', 'Em Breve', null, false);
        this.routesService.addItem('/clients/[0-9]/coming-soon', 'Em Breve', 'Em Breve', null, true);
    }
}
