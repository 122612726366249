import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SettingsService } from '../../../../../shared/services/settings.service';

@Component({
    selector: 'app-client-menu',
    templateUrl: './client-menu.component.html',
    styleUrls: ['./client-menu.component.css'],
    standalone: true,
    imports: [CommonModule, RouterLink, RouterLinkActive, TranslateModule]
})
export class ClientMenuComponent {

    @Input() clientId: number | null = null;

    constructor(public settingsService: SettingsService) {}
}
