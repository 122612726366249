<a class="nav-link dropdown-link" id="notificationDropdown" href="#" data-toggle="dropdown" (click)="removeNotificationPulse()">
    <div class="badge-wrapper">
        <i class="fa fa-bell-o d-none d-lg-block"></i>
        <span class="d-lg-none">{{ 'Notificações' | translate }}</span>
        <span class="badge badge-danger badge-notifications float-right float-lg-none" *ngIf="newNotifications">{{ newNotifications }}{{ newNotificationsPlus}}</span>
    </div>
</a>
<div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="notificationDropdown">
    <a class="dropdown-header d-none d-lg-block" *ngIf="newNotifications">
        Você tem {{ newNotifications }}{{ newNotificationsPlus}} novas notificações
    </a>
    <a class="dropdown-header d-none d-lg-block" *ngIf="!newNotifications">
        {{ 'Você não tem novas notificações' | translate }}
    </a>
    <div class="dropdown-divider mb-0 d-none d-lg-block"></div>
    <div class="nav-item-notifications-body">
        <a class="dropdown-item border-bottom pt-3 pb-3" (click)="setNotification(notification)" [routerLink]="notification.slug ? notification.slug : '/notifications/' + notification.id + '/view'" *ngFor="let notification of notifications">
            <div class="dropdown-title text-primary">
                <i class="fa fa-bell-o mr-1"></i><span
                    [class.font-weight-bold]="!notification.status">{{ notification.name }}</span>
            </div>
            <div class="small text-muted">
                {{ notification.created_at }}
            </div>
            <div class="dropdown-message" *ngIf="notification.type !== 'events_day'">
                {{ notification.content }}
            </div>
        </a>
    </div>
    <a class="dropdown-item small text-center" routerLink="/notifications">
        {{ 'Ir para Notificações' | translate }}
    </a>
</div> 