import { Component, OnInit } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Howl } from 'howler';

import { Notification as NotificationFC } from '../../../../pages/notifications/notification.model';
import { NotificationService } from '../../../../pages/notifications/notification.service';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RouterLink,
        TranslateModule
    ]
})
export class NotificationsComponent implements OnInit {

    public notifications: NotificationFC[] = [];
    public newNotifications = 0;
    public newNotificationsPlus = '';

    constructor(
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.checkNotificationInterval();
    }

    private getNotifications(): void {

        if (!environment.production) { return; }
        if (!navigator.onLine) {
            console.log('Não conectado à internet.');
            return;
        }

        if (localStorage.getItem('currentUser')) {

            this.notificationService.getAll(null, {
                    orderBy: 'created_at', sortedBy: 'desc', limit: 1000,
                    others: { noLoader: true, unread: true }
                }
            ).subscribe(resources => {

                let newNotifications = 0;
                const actual = this.newNotifications;
                for (const notification of resources.data) {
                    newNotifications += (!notification.status ? 1 : 0);
                }
                this.newNotifications = newNotifications;
                this.newNotificationsPlus = '';
                if (this.newNotifications >= 99) {
                    this.newNotificationsPlus = '+';
                }
                this.badgeNotifications();
                if (actual > 0 && actual < this.newNotifications) {
                    $('.badge-notifications').addClass('badge-pulse');
                    if ('Notification' in window && Notification.permission === 'granted' && document.visibilityState !== 'visible') {
                        const notification = new Notification(
                            resources.data[0].name, {
                                body: resources.data[0].content?.substring(0, 100),
                                icon: 'https://app.foodchecker.com.br/assets/imgs/logo/food_checker_bg_2x1.png',
                            });
                        if (resources.data[0].slug) {
                            notification.onclick = () => {
                                window.open(resources.data[0].slug, '_blank');
                            };
                        }
                    } else {
                        new Howl({ src: [ 'assets/imgs/notification.mp3' ] }).play();
                    }
                }
                this.notifications = resources.data.slice(0, 14);

                this.checkNotificationInterval();

            }, () => this.checkNotificationInterval());
        }
    }

    public setNotification(notification: NotificationFC): void {
        if (!notification.status) {
            notification.status = 1;
            --this.newNotifications;
            this.badgeNotifications();
            this.notificationService.update({
                id: notification.id, status: 1
            }, null, {
                others: { noLoader: true }
            }).subscribe();
        }
    }

    private badgeNotifications() {
        const badgeNotifications = document.querySelector('.badge-notifications');
        if (this.newNotifications) {
            badgeNotifications.innerHTML = this.newNotifications + '' + this.newNotificationsPlus;
        } else {
            badgeNotifications.innerHTML = '';
        }
    }

    public removeNotificationPulse() {
        document.querySelector('.badge-notifications')?.classList.remove('badge-pulse');
    }

    private checkNotificationInterval() {
        setTimeout(() => {
            this.getNotifications();
        }, 1000 * 30); // 30 segundos
    }
}
