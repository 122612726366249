<nav class="breadcrumb-line pl-2 pr-2 pl-sm-3 pr-sm-3 d-none d-lg-block">
    <div class="d-flex">
        <ol class="breadcrumb mr-auto pr-0">
            <li class="client breadcrumb-item d-flex align-items-center" *ngIf="clientId && clientName">
                <i class="fa fa-building-o mt-1 mr-1 d-none"></i>
                <img src="assets/imgs/svgs/{{ clientType ? clientType : 'branch' }}.svg" alt="{{ clientName }}" class="mr-1" height="16">
                <a [routerLink]="['/clients', clientId, 'view']">{{ clientName }}</a>
            </li>
            <li *ngFor="let item of items" class="breadcrumb-item" [class.active]="isTheLastItem(item)">
                <i *ngIf="isTheFirstItem(item) && !clientId" class="fa fa-home mt-1 mr-1"></i>
                <span *ngIf="isTheLastItem(item)">{{ item.text }}</span>
                <a *ngIf="!isTheLastItem(item)" [routerLink]="item.link">{{ item.text }}</a>
            </li>
        </ol>
        <ul class="nav-help pl-2 pr-0 pl-sm-3 pr-sm-3">
            <li class="nav-help-item">
                <a class="nav-help-link" href="https://know.foodchecker.com.br?utm_source=app&utm_medium=dashboard&utm_id=aprenda" target="_blank">
                    <span class="fa fa-question-circle"></span>
                    <span class="d-none d-sm-inline-block pl-1">Aprenda</span>
                </a>
            </li>
        </ul>
    </div>
</nav>
