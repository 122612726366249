import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { GeolocationService } from '../services/geolocation.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private geolocationService: GeolocationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const currentUser = this.authService.currentUserValue;
        if (currentUser && currentUser.token && request.url.indexOf('viacep') === -1 && request.url.indexOf('google') === -1) {
            request = request.clone({
                url: this.urlReplace(request.url),
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    Geolocation: this.geolocationService.geolocation
                }
            });
        } else {
            request = request.clone({
                url: this.urlReplace(request.url),
                setHeaders: {
                    Geolocation: this.geolocationService.geolocation
                }
            });
        }

        return next.handle(request)
            .pipe(
                tap((event: HttpEvent<any>) => {
                    this.setNewTokenIfResponseValid(event);
                }, (eventError: HttpEvent<any>) => {
                    this.setNewTokenIfResponseValid(eventError);
                })
            );
    }

    private setNewTokenIfResponseValid(event: HttpEvent<any>) {
        if (event instanceof HttpResponseBase) {
            const authorizationHeader = event.headers.get('Authorization');
            if (authorizationHeader) {
                const token = authorizationHeader.split(' ')[1];
                this.authService.setNewToken(token);
            }
            const subscriberHeader = event.headers.get('Subscriber');
            if (subscriberHeader) {
                localStorage.setItem('subscriber', subscriberHeader);
            }
            const planHeader = event.headers.get('Plan');
            if (planHeader) {
                localStorage.setItem('plan', planHeader);
            }
            const statusHeader = event.headers.get('Situation');
            if (statusHeader) {
                localStorage.setItem('situation', statusHeader);
            }
            localStorage.setItem('css', event.headers.get('Css'));
        }
    }

    private urlReplace(url: string): string {

        if (url.indexOf('set_access') !== -1 || url.indexOf('register') !== -1) {

            const name = 'fc_utm_data';
            const cookie = document.cookie.split(';').find(c => c.trim().startsWith(name + '='));

            if (cookie) {
                const utms = JSON.parse(decodeURIComponent(cookie.replace(name + '=', '')));
                const params = Object.keys(utms)
                    .map(function (key) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(utms[key]);
                    }).join('&');

                const separator = url.includes('?') ? '&' : '?';
                url = `${url}${separator}${params}`;
            }
        }

        return url;
    }
}
