<div class="app-body">

    <header>
        <div class="row mb-4 mx-xs-0">
            <div class="col text-center px-xs-0">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <button class="navbar-toggler ml-auto text-white" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Alterna navegação">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div class="navbar-nav ml-auto">
                            <a class="nav-item nav-link cursor-pointer" routerLink="/help">Artigos</a>
                            <a class="nav-item nav-link cursor-pointer" routerLink="/help/tickets" *ngIf="showTickets">Falar com Suporte</a>
                        </div>
                    </div>
                </nav>
                <a routerLink="/">
                    <img src="assets/imgs/logo/logo-h-br-40.png">
                </a>
            </div>
        </div>
    </header>

    <div class="container-fluid container-page">
        <router-outlet></router-outlet>
    </div>

    <footer class="d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-lg">
                    <p>© 2015 - 2019. <a href="#">Food Checker</a> by <a href="#">Nutri Consultora</a></p>
                </div>
                <div class="col-lg">
                    <ul class="list-inline text-right">
                        <li class="list-inline-item">
                            <a href="#"><i class="fa fa-facebook-square"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#"><i class="fa fa-youtube-square"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#"><i class="fa fa-linkedin-square"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#"><i class="fa fa-external-link-square"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>

</div>
