import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import jsQR from 'jsqr';

@Component({
    selector: 'app-qr-code',
    templateUrl: './qr-code.component.html',
    styleUrls: ['./qr-code.component.css'],
    standalone: true,
    imports: [CommonModule]
})
export class QrCodeComponent implements OnInit, OnDestroy {

    @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;
    @ViewChild('canvas') canvasElement: ElementRef<HTMLCanvasElement>;

    public isNativeScannerActive = false;
    public isWebScanner = false;
    public scanActive = false;
    public scanError = false;
    public errorMessage = '';
    public videoStream: MediaStream | null = null;
    public animationFrameId: number | null = null;
    public showScanner = false;
    public isFlashOn = false;

    private previewInterval: number | null = null;
    private readonly ALLOWED_DOMAINS = [
        'app.foodchecker.com.br',
        'beta.foodchecker.com.br',
        'foodchecker.com.br',
        'localhost'
    ];

    constructor(
        private zone: NgZone,
        private router: Router
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.stopScan();
    }

    async openQrScanner() {
        try {
            this.showScanner = true;
            await this.startScan();
        } catch (error) {
            console.error('Erro ao preparar o scanner:', error);
            this.scanError = true;
            this.errorMessage = 'Erro ao preparar o scanner. Tente novamente.';
        }
    }

    async startScan() {
        this.scanActive = true;
        this.scanError = false;

        try {
            if (Capacitor.isNativePlatform()) {
                await this.startNativeScan();
            } else {
                this.isWebScanner = true;
                await this.startWebScan();
            }
        } catch (error) {
            console.error('Erro ao iniciar o scanner:', error);
            this.scanError = true;
            this.errorMessage = this.getReadableErrorMessage(error);
            this.stopScan();
        }
    }

    private async processImage(imageData: ImageData): Promise<boolean> {
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
            if (code.data && code.data.trim().length >= 5) {
                this.zone.run(() => {
                    this.handleQrCodeResult(code.data);
                });
                return true;
            }
        }
        return false;
    }

    private async startNativeScan() {
        this.isNativeScannerActive = true;
        try {
            const cameraPreviewOptions: CameraPreviewOptions = {
                position: 'rear',
                parent: 'camera-preview',
                className: 'camera-preview',
                width: window.innerWidth,
                height: window.innerHeight / 2,
                y: window.innerHeight / 4,
                x: 0,
                toBack: false,
                disableAudio: true
            };

            await CameraPreview.start(cameraPreviewOptions);

            // Função para capturar e processar frames
            const captureFrame = async () => {
                if (!this.scanActive) return;

                try {
                    const capture = await CameraPreview.captureSample({
                        quality: 90
                    });

                    if (capture.value) {
                        const img = new Image();
                        img.onload = async () => {
                            const canvas = document.createElement('canvas');
                            canvas.width = img.width;
                            canvas.height = img.height;
                            const context = canvas.getContext('2d');
                            context.drawImage(img, 0, 0);

                            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                            const found = await this.processImage(imageData);

                            if (!found) {
                                this.previewInterval = requestAnimationFrame(captureFrame);
                            }
                        };
                        img.src = `data:image/jpeg;base64,${capture.value}`;
                    } else {
                        this.previewInterval = requestAnimationFrame(captureFrame);
                    }
                } catch (error) {
                    console.error('Erro ao capturar frame:', error);
                    this.previewInterval = requestAnimationFrame(captureFrame);
                }
            };

            this.previewInterval = requestAnimationFrame(captureFrame);

        } catch (error) {
            console.error('Erro no scanner nativo:', error);
            this.scanError = true;
            this.errorMessage = this.getReadableErrorMessage(error);
        }
    }

    private async startWebScan() {
        const constraints: MediaStreamConstraints = {
            video: {
                facingMode: 'environment',
                width: {
                    min: 600,
                    ideal: 1920,
                    max: 1920,
                },
                height: {
                    min: 600,
                    ideal: 1080,
                    max: 1080,
                },
            },
            audio: false
        };

        try {
            this.videoStream = await navigator.mediaDevices.getUserMedia(constraints);

            setTimeout(() => {
                if (this.videoElement && this.videoElement.nativeElement) {
                    this.videoElement.nativeElement.srcObject = this.videoStream;
                    this.videoElement.nativeElement.onloadedmetadata = () => {
                        this.videoElement.nativeElement.play();
                        this.scanQrCode();
                    };
                }
            }, 100);

        } catch (error) {
            console.error('Erro ao acessar a câmera:', error);
            this.scanError = true;
            this.errorMessage = this.getReadableErrorMessage(error);
            throw error;
        }
    }

    private async scanQrCode() {
        if (!this.scanActive) return;

        if (this.videoElement && this.videoElement.nativeElement && this.canvasElement && this.canvasElement.nativeElement) {
            const video = this.videoElement.nativeElement;
            const canvas = this.canvasElement.nativeElement;
            const context = canvas.getContext('2d', { willReadFrequently: true });

            if (context && video.readyState === video.HAVE_ENOUGH_DATA) {
                canvas.height = video.videoHeight;
                canvas.width = video.videoWidth;

                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

                const found = await this.processImage(imageData);

                if (!found) {
                    this.animationFrameId = requestAnimationFrame(() => this.scanQrCode());
                }
            }
        }
    }

    private handleQrCodeResult(data: string) {
        this.stopScan();

        if (data && data.trim()) {
            try {
                const url = new URL(data);
                const domain = url.hostname;

                if (this.ALLOWED_DOMAINS.includes(domain)) {

                    const path = url.pathname;
                    const queryParams: { [key: string]: string } = {};

                    url.searchParams.forEach((value, key) => {
                        queryParams[key] = value;
                    });

                    this.closeScanner();
                    console.log('Navegando para:', path, 'com parâmetros:', queryParams);

                    this.router.navigate([path], {
                        queryParams: queryParams
                    });

                } else {
                    this.scanError = true;
                    this.errorMessage = 'Link inválido. Apenas links do FoodChecker são permitidos.';
                }
            } catch (error) {
                this.scanError = true;
                this.errorMessage = 'Link inválido. Verifique o QR Code e tente novamente.';
            }
        } else {
            this.closeScanner();
        }
    }

    async stopScan() {
        this.scanActive = false;

        if (this.videoStream) {
            this.videoStream.getTracks().forEach(track => track.stop());
            this.videoStream = null;
        }

        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }

        if (this.previewInterval) {
            cancelAnimationFrame(this.previewInterval);
            this.previewInterval = null;
        }

        if (Capacitor.isNativePlatform()) {
            try {
                await CameraPreview.stop();
            } catch (error) {
                console.error('Erro ao parar preview da câmera:', error);
            }
        }
    }

    tryAgain() {
        this.startScan();
    }

    openManualInput() {
        const url = prompt('Digite a URL manualmente:');
        if (url) {
            this.handleQrCodeResult(url);
        } else {
            this.closeScanner();
        }
    }

    closeScanner(url?: string) {
        this.showScanner = false;
        this.scanError = false;
        this.stopScan();
    }

    getReadableErrorMessage(error: any): string {
        if (error.name === 'NotAllowedError') {
            return 'Permissão para acessar a câmera foi negada.';
        } else if (error.name === 'NotFoundError') {
            return 'Não foi possível encontrar uma câmera.';
        } else if (error.name === 'NotReadableError') {
            return 'A câmera está sendo usada por outro aplicativo.';
        }
        return 'Erro ao acessar a câmera. Tente novamente.';
    }

    async toggleFlash() {
        try {
            if (this.isNativeScannerActive) {
                const flashMode = this.isFlashOn ? 'off' : 'torch';
                await CameraPreview.setFlashMode({ flashMode });
                this.isFlashOn = !this.isFlashOn;
            }
        } catch (error) {
            console.error('Erro ao alternar flash:', error);
        }
    }
}
