import { Injectable } from '@angular/core';
import { RoutePartInterface } from '../interfaces/route-part.interface';
import { CrumbInterface } from '../interfaces/crumb.interface';

@Injectable({
    providedIn: 'root'
})
export class RoutesService {

    public url: string;
    private parts: RoutePartInterface[] = [];

    private static clear(url: string) {
        return url.replace('/[0-9]', '')
                  .replace('/[0-9]', '')
                  .replace('/*', '')
                  .replace(/\/\d+/g, '')
                  .replace(/\/page\/[a-z\-]+/g, '/page')
                  .replace(/\/help\/article\/[a-z\-]+/g, '/help/article')
                  .replace(/\/help\/category\/[a-z\-]+/g, '/help/category')
                  .replace(/\/(tk|tp)\/[a-zA-Z0-9_\-]+/g, '');
    }

    private static formatLink(link, pageUrl) {
        link = link.replace('/[0-9]', '');
        if (/^\/clients\/([0-9]+)\/[a-z-_]+\/([0-9a-z-_]+)/g.test(pageUrl)) {
            const reg = /\/clients\/[0-9]+/g.exec(pageUrl)[0];
            return link.replace('/clients', reg);
        }
        return link;
    }

    public addItem(route: string, name: string, pageTitle: string, parent: string, client: boolean): void {
        parent = parent ? parent : null;
        route = RoutesService.clear(route);
        this.parts[route] = { name, pageTitle, parent, route, client };
    }

    public getItem(route?: string): RoutePartInterface {
        route = route ? route : this.url;
        route = route.split('?')[0];
        route = RoutesService.clear(route);
        if (route in this.parts) {
            return this.parts[route];
        } else {
            route = route.substr(0, route.lastIndexOf('/'));
            if (route in this.parts) {
                return this.parts[route];
            } else {
                route = route.substr(0, route.lastIndexOf('/'));
                if (route in this.parts) {
                    return this.parts[route];
                }
            }
        }
        return this.parts['/'];
    }

    public getCrumbs(url: string): CrumbInterface[] {
        const items: CrumbInterface[] = [];
        const pageUrl = url;
        while (url) {
            const item = this.getItem(url);
            if (item) {
                items.push({
                    text: item.name,
                    link: RoutesService.formatLink(item.route, pageUrl),
                    parent: item.parent ? RoutesService.formatLink(item.parent, pageUrl) : null
                });
                url = item.parent;
            } else {
                url = null;
            }
        }
        return items.reverse();
    }
}
