import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const localeId = localStorage.getItem('language') ? localStorage.getItem('language').replace('_', '-') : 'pt-BR';

registerLocaleData(localePt, localeId);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
