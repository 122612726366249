import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

    public attemptedRoute: string;

    constructor(private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.attemptedRoute = this.route.snapshot.url.join('/');
    }
}
