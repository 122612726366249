import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { RoutesService } from '../../shared/services/routes.service';

@Component({
    selector: 'app-public-layout',
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.css'],
    imports: [RouterOutlet]
})
export class PublicLayoutComponent {

    title: string;
    pageClass = '';
    url: string;

    constructor (
        private router: Router,
        private routesService: RoutesService,
        private titleService: Title
    ) {

        $('body').removeClass('app-admin');
        $('body').removeClass('environment-development');
        $('body').addClass('p-login');

        this.url = this.router.url;

        // change routes
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.title = this.routesService.getItem(this.router.url)?.pageTitle;
                this.titleService.setTitle( 'Food Checker - ' + this.title );
            }
        });
    }
}
