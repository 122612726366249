<a class="nav-link" href="javascript:;" (click)="openQrScanner()" title="Ler QR Code">
    <i class="fa fa-qrcode"></i>
</a>

<div class="scanner-overlay" *ngIf="showScanner">
    <div class="scanner-container" [class.scanner-active]="isNativeScannerActive">

        <div id="camera-preview" *ngIf="!scanError && isNativeScannerActive">
            <p>Encontre um código QR</p>
            <button class="close-button" [class.on]="isFlashOn" (click)="toggleFlash()">
                <i class="fa fa-flash"></i>
            </button>
        </div>

        <div class="viewport" *ngIf="!scanError && isWebScanner">
            <canvas #canvas hidden></canvas>
            <video #video autoplay playsinline></video>
            <div class="scan-region-highlight"></div>
            <div class="scan-region-highlight-svg">
                <img src="assets/imgs/svgs/qr-code-scan.svg" alt="">
            </div>
        </div>

        <div class="error-container" *ngIf="scanError">
            <i class="fa fa-exclamation-triangle error-icon"></i>
            <p>{{ errorMessage }}</p>
            <button class="btn btn-primary" (click)="tryAgain()">Tentar novamente</button>
            <button class="btn btn-outline-light" (click)="openManualInput()">Inserir link manualmente</button>
        </div>

        <button class="close-button" (click)="closeScanner()">
            <i class="fa fa-times"></i>
        </button>

    </div>
</div>
