import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { CrumbInterface } from '../../../../../shared/interfaces/crumb.interface';
import { RoutesService } from '../../../../../shared/services/routes.service';
import { RoutesData } from '../../../../../shared/data/routes.data';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
    standalone: true,
    imports: [CommonModule, RouterLink]
})
export class BreadcrumbComponent {

    @Input() clientId: number | null = null;
    @Input() clientName: string | null = null;
    @Input() clientType: string | null = null;

    public items: Array<CrumbInterface> = [];

    constructor(
        private routesService: RoutesService,
        private routesData: RoutesData // não remover
    ) { }

    public generateBreadcrumbTrail(url: string): void {
        this.items = this.routesService.getCrumbs(url);
        this.updateBodyParentAttribute();
    }

    private updateBodyParentAttribute(): void {
        const lastItem = this.items[this.items.length - 1];
        const backButton = document.getElementById('back-button');
        if (backButton) {
            if (lastItem?.parent) {
                backButton.style.display = 'inline';
                backButton.setAttribute('data-parent', lastItem.parent);
            } else {
                backButton.style.display = 'none';
                backButton.removeAttribute('data-parent');
            }
        }
    }

    public isTheLastItem(item: CrumbInterface): boolean {
        const index = this.items.indexOf(item);
        return index + 1 === this.items.length;
    }

    public isTheFirstItem(item: CrumbInterface): boolean {
        return this.items.indexOf(item) === 0;
    }
}
