import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { HelpLayoutComponent } from './layouts/help/help-layout.component';
import { PublicLayoutComponent } from './layouts/public/public-layout.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { AuthGuard } from './shared/guards/auth.guard';
import { PayingGuard } from './shared/guards/paying.guard';

const routes: Routes = [

    // tslint:disable:max-line-length

    // logged
    {
        path: '', component: AdminLayoutComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'registered-successfully', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'clients', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule) },
            { path: 'scrum', loadChildren: () => import('./pages/scrum/scrum.module').then(m => m.ScrumModule) },
            { path: 'providers', loadChildren: () => import('./pages/providers/providers.module').then(m => m.ProvidersModule) },
            { path: 'legislations', loadChildren: () => import('./pages/legislations/legislations.module').then(m => m.LegislationsModule) },
            { path: 'connections', loadChildren: () => import('./pages/connections/connections.module').then(m => m.ConnectionsModule) },
            { path: 'podcasts', loadChildren: () => import('./pages/podcasts/podcasts.module').then(m => m.PodcastsModule) },
            { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) },
            { path: 'calendars', loadChildren: () => import('./pages/calendars/calendars.module').then(m => m.CalendarsModule) },
            { path: 'harvests', loadChildren: () => import('./pages/harvests/harvests.module').then(m => m.HarvestsModule) },
            { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule) },
            { path: 'payments', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule) },
            { path: 'recommendations', loadChildren: () => import('./pages/recommendations/recommendations.module').then(m => m.RecommendationsModule) },
            { path: 'queues', loadChildren: () => import('./pages/queues/queues.module').then(m => m.QueuesModule) },
            { path: 'tests', loadChildren: () => import('./pages/tests/tests.module').then(m => m.TestsModule) },
            { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
            { path: 'company', loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
            { path: 'settings', loadChildren: () => import('./pages/settings/setting.module').then(m => m.SettingModule) },
            { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule) },
            { path: 'page', loadChildren: () => import('./pages/pages/pages.module').then(m => m.PagesModule) },
            { path: 'coming-soon', loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
            { path: 'support', loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule) },

            // clients
            { path: 'clients/:client_id/visits', loadChildren: () => import('./pages/visits/visits.module').then(m => m.VisitsModule) },
            { path: 'clients/:client_id/sheets', loadChildren: () => import('./pages/sheets/sheets.module').then(m => m.SheetsModule) },
            { path: 'clients/:client_id/projects', loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule) },
            { path: 'clients/:client_id/categories', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule) },
            { path: 'clients/:client_id/checklists', loadChildren: () => import('./pages/checklists/checklists.module').then(m => m.ChecklistsModule) },
            { path: 'clients/:client_id/forms', loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormsModule) },
            { path: 'clients/:client_id/labels', loadChildren: () => import('./pages/labels/labels.module').then(m => m.LabelsModule) },
            { path: 'clients/:client_id/datasheets', loadChildren: () => import('./pages/datasheets/datasheets.module').then(m => m.DatasheetsModule) },
            { path: 'clients/:client_id/packagings', loadChildren: () => import('./pages/packagings/packagings.module').then(m => m.PackagingsModule) },
            { path: 'clients/:client_id/action-plans', loadChildren: () => import('./pages/action-plans/action-plans.module').then(m => m.ActionPlansModule) },
            { path: 'clients/:client_id/scrum', loadChildren: () => import('./pages/scrum/scrum.module').then(m => m.ScrumModule) },
            { path: 'clients/:client_id/contacts', loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule) },
            { path: 'clients/:client_id/ingredients', loadChildren: () => import('./pages/ingredients/ingredients.module').then(m => m.IngredientsModule) },
            { path: 'clients/:client_id/recipes', loadChildren: () => import('./pages/recipes/recipes.module').then(m => m.RecipesModule) },
            { path: 'clients/:client_id/sops', loadChildren: () => import('./pages/sops/sops.module').then(m => m.SopsModule) },
            { path: 'clients/:client_id/documents', loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule) },
            { path: 'clients/:client_id/trainings', loadChildren: () => import('./pages/trainings/trainings.module').then(m => m.TrainingsModule) },
            { path: 'clients/:client_id/reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) },
            { path: 'clients/:client_id/users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule) },
            { path: 'clients/:client_id/coming-soon', loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
        ]
    },
    // paying
    {
        path: '', component: AdminLayoutComponent,
        canActivate: [ AuthGuard, PayingGuard ],
        children: []
    },
    // public
    {
        path: '', component: PublicLayoutComponent,
        children: [
            { path: 'login', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) }
        ]
    },
    // help
    {
        path: '', component: HelpLayoutComponent,
        children: [
            { path: 'help', loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule) },
        ]
    },
    // { path: '**', redirectTo: '' }
    {
        path: '**',
        pathMatch: 'full',
        canActivate: [],
        component: NotFoundComponent
    }

];

@NgModule({
    imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
}) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
}
