<ul class="nav navbar-nav">
    <li class="nav-item hide-app-inactive" routerLinkActive="active">
        <a class="nav-link" routerLink="/calendars">{{ 'Agenda' | translate }}</a>
    </li>
    <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/clients">{{ 'Clientes' | translate }}</a>
    </li>
    <li class="nav-item" *ngIf="!settingsService.getBool('no-menu-providers')">
        <a class="nav-link" routerLink="/providers">{{ 'Fornecedores' | translate }}</a>
    </li>
    <li class="nav-item dropdown d-none d-lg-block d-xl-none">
        <a class="nav-link dropdown-toggle dropdown-link" href="#" id="moreDropdown"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ 'Mais' | translate }}...
        </a>
        <div class="dropdown-menu navbar-dropdown" aria-labelledby="moreDropdown">
            <a class="dropdown-item" routerLink="/harvests"
               *ngIf="!settingsService.getBool('no-menu-harvests')">{{ 'Safras' | translate }}</a>
            <a class="dropdown-item" routerLink="/legislations"
               *ngIf="!settingsService.getBool('no-menu-legislations')">{{ 'Legislações' | translate }}</a>
            <a class="dropdown-item not-role-4 hide-app-inactive" routerLink="/reports/all/new"
               *ngIf="!settingsService.getBool('no-menu-reports-all')">{{ 'Relatórios' | translate }}</a>
            <a class="dropdown-item not-role-4" routerLink="/connections"
               *ngIf="!settingsService.getBool('no-menu-connections')">{{ 'Conexões' | translate }}</a>
        </div>
    </li>
    <li class="nav-item d-lg-none d-xl-block" *ngIf="!settingsService.getBool('no-menu-harvests')">
        <a class="nav-link" routerLink="/harvests">{{ 'Safras' | translate }}</a>
    </li>
    <li class="nav-item d-lg-none d-xl-block" *ngIf="!settingsService.getBool('no-menu-legislations')">
        <a class="nav-link" routerLink="/legislations">{{ 'Legislações' | translate }}</a>
    </li>
    <li class="nav-item d-lg-none d-xl-block not-role-4 hide-app-inactive"
        *ngIf="!settingsService.getBool('no-menu-reports-all')">
        <a class="nav-link" routerLink="/reports/all/new">{{ 'Relatórios' | translate }}</a>
    </li>
    <li class="nav-item d-lg-none d-xl-block not-role-4"
        *ngIf="!settingsService.getBool('no-menu-connections')">
        <a class="nav-link" routerLink="/connections">{{ 'Conexões' | translate }}</a>
    </li>
</ul> 