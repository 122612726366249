import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class DebugService {

    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) { }

    public sendDebugData(debugData: any): void {

        const url = `${environment.API_URL}debug/mail`;
        const params = new HttpParams().set('noLoader', 'true');

        let preparedData;

        if (Array.isArray(debugData)) {
            preparedData = { type: 'array', data: debugData };
        } else if (debugData instanceof Error) {
            preparedData = {
                type: 'error',
                message: debugData.message,
                stack: debugData.stack,
                name: debugData.name
            };
        } else if (typeof debugData === 'string') {
            preparedData = { type: 'string', data: debugData };
        } else if (typeof debugData === 'object') {
            preparedData = { type: 'object', data: debugData };
        } else {
            preparedData = { type: 'unknown', data: debugData };
        }

        preparedData.subject = 'Debug data';
        if (preparedData.data && preparedData.data.subject) {
            preparedData.subject = preparedData.data.subject;
        }

        preparedData.user = this.authService.currentUserValue;
        // preparedData.user.email = Base64.decode(preparedData.user.email);

        this.http.post(url, preparedData, {params}).subscribe(
            response => {
                console.log('Debug data sent successfully', response);
            },
            error => {
                console.error('Error sending debug data', error);
            }
        );
    }
}
